@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/mixins/rtl";
@import "../../../styles/mixins/typography";

.spson-banner {
  padding: 16px;
  background-color: $white-ice;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $screen-xs-max) {
    flex-direction: column;
    align-items: flex-start;

    margin: 0 -16px;
  }

  @media (min-width: $screen-md-min) {
    padding: 16px 24px 24px;
  }

  &__content {
    flex: 1 1;

    max-width: 470px;

    @media (max-width: $screen-xs-max) {
      margin-bottom: 24px;
    }
  }

  &__actions {
    display: flex;
    flex: 1 1;

    align-items: center;
    width: 100%;

    @media (min-width: $screen-sm-min) {
      justify-content: flex-end;
    }
  }

  .tooltip-referrer {
    @include side-spacing(margin, left, 16px);
  }

  &__button {
    width: 100%;

    @media (min-width: $screen-xs-min) {
      max-width: 225px;
    }
  }

  &__title {
    display: flex;
    align-items: baseline;

    @include font-weight(semi-bold);
    margin-bottom: 8px;

    .icon {
      @include side-spacing(margin, right, 8px);
      color: var(--color-primary);
    }
  }

  &__extra-info {
    padding: 8px 10px;
    font-size: 20px;
  }

  .tooltip-referrer {
    display: block;
  }
}
