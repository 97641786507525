@import "../../styles/colors";
@import "../../styles/variables";

.wizard-page {
  margin-top: 24px;

  .go-back-btn {
    display: inline-block;
    margin-bottom: 16px;

    @media (max-width: $screen-sm-max) {
      margin-bottom: 24px;
    }
  }

  .spson-banner {
    margin-bottom: 16px;
  }

  .profile-picker__header {
    margin-top: 0;
  }

  &__btn-container {
    display: flex;
    justify-content: flex-end;
  }

  &--single {
    .profiles__section {
      margin-top: 0;
    }
  }
}
