@import "../../../styles/variables";

.spson-reminder {
  .modal__footer {
    @media(max-width: $screen-xs-max) {
      .btn {
        width: 100%;
      }
    }
  }
}
