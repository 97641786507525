@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/variables";

.home-page__dashboard {
  .spson-banner {
    margin-bottom: 16px;
  }

  .dashboard-row + .dashboard-row {
    @media (min-width: $screen-md-min) {
      margin-top: 32px;
    }

    @media (max-width: $screen-sm-max) {
      margin-top: 24px;
    }
  }

  @media (min-width: $screen-md-min) {
    .dashboard-row {
      display: grid;
      grid-template-columns: 100%;
    }

    .with-aside {
      column-gap: 26px;
      grid-template-columns: calc(100% - 326px) 300px;
    }
  }
}

.home-page__bar-chart {
  width: 100%;
  height: 235px;
}

.home-page__title {
  padding-bottom: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid $geyser;
}

.homepage-reports {
  &__hint {
    @include text-caption;
    margin-top: 8px;
  }

  &__title {
    margin-bottom: 8px;
  }
}

.chart-title {
  margin-bottom: 16px;

  & + .scrollable-container {
    margin-top: 16px;
  }

  @media (max-width: $screen-xs-max) {
    margin-bottom: 8px;
  }
}
