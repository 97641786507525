@import "../../styles/mixins/rtl";
@import "../../styles/mixins/typography";
@import "../../styles/colors";
@import "../../styles/variables";

.deeplink-modal {
  .btn {
    width: 100%;
  }

  &--success .modal__footer{
    display: block;
  }

  &__status {
    @include text-caption;
  }

  &__status &__icon {
    @include font-size(medium);
    @include side-spacing(margin, right, 8px);
  }
  &__text {
    .deeplink-modal--error & {
      margin-top: 16px;

      @media(min-width: $screen-sm-min) {
        text-align: center;
        margin: 16px 16px 0;
      }
    }
  }

  &__list {
    list-style: decimal;
    @include side-spacing(margin, left, 16px);
  }

  &__instruction-details {
    margin-top: 16px;
    padding: 0 16px;
    background-color: $black-haze;
    color: $mid-gray;

    &--toggle{
      display: flex;
      justify-content: space-between;

      padding: 12px 0;

      cursor: pointer;

      .icon {
        @include font-size(medium);
      }
    }

    &--text {
      @include font-size(small);
      padding-bottom: 12px;
    }
  }

  &--success {
    .deeplink-modal__status .deeplink-modal__icon {
      color: var(--color-success);
    }
  }

  &--error {
    .deeplink-modal__status .deeplink-modal__icon {
      color: var(--color-danger);
    }
  }

  @media (min-width: $screen-sm-min) {
    &__status {
      text-align: center;
    }

    &--error &__text {
      margin-top: 44px;
    }
  }
}
